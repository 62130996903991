//公共
export const c = {
  Language: {
    name: 'English'
  },
  Login:{
    ItemUniqueCode: '链接OID',
    CodeDescribe: '万物互联的时代，我们一“码”当先！',
    UserName: '邮箱/用户名',
    Password: '密码',
    Rememb: '记住密码',
    SignIn: '注册账号',
    SignUp: '登录',
    Logging: '登录中...',
    ForgetPassword: '忘记密码',
    Hint: '如果因为浏览器无法兼容问题请',
    CustomerServiceDownload: '下载客服端',
  },
  HeadBar:{
    HomePage: '首页',
    GoLogin: '登录',
    FunctionPackage: '生码套餐',
    BillingManagement: '费用中心',
    Service: '在线客服',
    Documents: '操作文档',
    BasicInfo: '基本信息',
    ChangePassword: '修改密码',
    SecuritySettings: '安全设置',
    Logout: '退出登录',
    ViewMore: '查看更多'
  },
  LeftNav:{
    //管理员
    ChangeActive: '兑换码激活',
    Financial: '财务管理',
    Kanban: '看板模式',
    Organization: '组织管理',
    Business: '商户管理',
    TrademarkAudit: '商标审核',
    ProductAudit: '产品审核',
    UniversalCode: '万能码生成',
    barCode: '条形码生成',
    Developer: '开发者管理',
    TemplateSetting: '模板设置',
    TemplateDatas: '模板内容',
    TemplateList: '模板列表',
    Push: '消息推送',
    PushList: '推送列表',
    Message: '留言反馈',
    QualityAppeal: '质量诉求',
    Invoice: '发票管理',
    Setting: '设置管理',
    AuditList: '审核列表',
    DeveloperList: '开发者列表',
    Blacklist: '黑名单',
    InvoiceSetting: '发票设置',
    InvoiceList: '发票列表',
    CreateCodeAudit: '生码规则',
    FunctionSetting: '套餐设置',
    DocsSetting: '文档设置',
    UserAgreement: '用户协议',
    OthersSetting: '其他设置',
    CodeAllot: '万能码分配',
    Register: '注册登记',
    DeviceManage: '设备管理',
    //商户
    HomePage: '首页展示',
    ProductManage: '产品管理',
    CodeManage: '码标管理',
    BatchManage: '批次管理',
    CodeActive: '码标激活',
    Promotion: '促销活动',
    WinningRecord: '中奖记录',
    WithdrawalRecord: '提现记录',
    PrizeExchange: '奖品兑换',
    UserStats: '用户统计',
    PromotionSet: '促销设置',
    CodeScanQRActive: '扫码激活',
    CodeExtentActive: '首尾码激活',
    CodeFileActive: '文件激活',
    CodePacket: '码标分组',
    BarCodePacket: '条形码分组',
    ProcessManage: '工序管理',
    ProcessList: '工序列表',
    ProcessAllot: '工序分配',
    DealerManage: '经销商管理',
    DealerList: '经销商列表',
    DealerShipping: '经销商发货',
    DeliveryRecord: '发货记录',
    ReceiptRecord: '收货记录',
    MaterialManage: '素材管理',
    ImagesSpace: '图片空间',
    VideosSpace: '视频空间',
    AudioSpace: '音频空间',
    Statistics: '查询统计',
    CodeQuery: '码标查询',
    GroupQuery: '分组查询',
    FleeingGoodsList: '窜货列表',
    ScanQRStats: '扫码统计',
    ScanQRRecords: '扫码记录',
    QueryQRRecords: '查询记录',
    MobileQRRecords: '手机记录',
    BatchQuery: '批次查询',
    Account: '账号管理',
    BasicInfo: '基本信息',
    FunctionPackage: '生码套餐',
    BillingManagement: '费用中心',
    IncomeDisbursement: '收支明细',
    InvoiceManage: '发票管理',
    Developer: '开发者中心',
    SecuritySettings: '安全设置',
    Brand: '商标认证',
    Market: '营销活动',
    ShhfBrand: '品牌管理',
    Licensor: '授权管理',
    Fields: '产地位置',
    searchRecord: '搜索记录',
    Replace: '码标替换',
    //网点
    StockManage: '库存管理',
  },
  Column:{
    ClickTo: '点击前往',
    ProductManage: '产品管理',
    AddProduct: '添加产品',
    EditProduct: '编辑产品',
    DeviceManage: '产品管理',
    AddDevice: '添加产品',
    EditDevice: '编辑设备',
    TemplateSettings: '模板设置',
    TemplateEdit: '模板编辑',
    BatchManage: '批次管理',
    QualityInformation: '质检信息',
    Brand: '商标认证',
    AddBrand: '添加商标',
    ChangeBrand: '修改商标',
    ProcessList: '工序列表',
    AddProcess: '添加工序',
    EditProcess: '编辑工序',
    DemoProcess: '演示溯源',
    DealerList: '经销商列表',
    AddDealer: '添加经销商',
    EditDealer: '编辑经销商',
  },
  Common:{
    yes: '是',
    no: '否',
    Account: '账号',
    Confirm: '确定',
    Cancel: '取消',
    Search: '搜索',
    Level: '级',
    OIDAuthentication: 'OID认证',
    SecurityAuthentication: '防伪认证',
    Name: '名称',
    Alias: '别名',
    Text: '文字',
    Title: '标题',
    Image: '图片',
    State: '状态',
    CreationTime: '创建时间',
    Operation: '操作',
    Edit: '编辑',
    Copy: '复制',
    Preview: '预览',
    Change: '修改',
    Save: '保存',
    Back: '返回',
    Delete: '删除',
    Completed: '已完成',
    Passed: '已通过',
    InProgress: '处理中',
    InReview: '审核中',
    Failure: '失败',
    Refer: '未通过',
    Verified: '已认证',
    Disabled: '禁用',
    Corvidae: '待完善',
    Expired: '已失效',
    Product: '产品',
    ProductName: '产品名称',
    VideoLink: '视频链接',
    Amount: '数量',
    SuccessAmount: '成功数量',
    Download: '下载',
    Type: '类型',
    SecurityCode: '防伪码',
    SequenceCode: '流水码',
    ActivateWay: '激活方式',
    DefaultActivation: '默认激活',
    OutboundActivation: '出库激活',
    ResidueCodes: '剩余生码量',
    Remarks: '备注',
    Batch: '批次',
    Numbering: '编号',
    Scan: '采集',
    Extent: '首尾码',
    File: '文件',
    ScanActive: '采集激活',
    ExtentActive: '首尾码激活',
    FileActive: '文件激活',
    ActiveCode: '激活码标',
    CodeNumber: '码标数量',
    FirstCode: '码标首码',
    LastCode: '码标尾码',
    CodeFile: '码标文件',
    CurrentCodeList: '当前采集列表',
    Unit:'个',
    Clear: '清空',
    ActivateNumber: '激活数量',
    FileUpload: '上传文件',
    FileSizeHint: '上传的文件大小不能超过5M',
    ClickView: '点击查看',
    Linkman: '联系人',
    Phone: '联系电话',
    Address: '联系地址',
    Manage: '管理',
    Stock: '库存',
    DatasImport:'数据导入',
    Infinite: '无限',
    Process: '工序',
    SuccessfullyQuantity: '成功数量',
    SelectAll: '全选',
    DeleteSelection: '删除选中',
    MovePacket: '移动分组',
    Rename: '重命名',
    Function: '功能',
    Describe: '描述',
    Cost: '费用',
    ExpirationDate: '到期时间',
    CreditCard: '未开通',
    HaveOpened: '已开通',
    Buy: '购买套餐',
    HistoryBuy: '上次续费',
    NotPackage: '无套餐',
    FreeTrial: '免费试用',
    WeChatPay: '微信支付',
    S:'秒',
    Yuan: '元',
    Settings: '设置',
    HaveSet: '已设置',
    NotSet: '未设置',
    GlobalIdentity: '全球统一标识',
    TotalConsumption: '总消费',
    PayTime: '订单到期时间倒计时',
    FileHint: '列表文件云端存储时间为一个月，如要多次或长期使用请自行下载文件包进行保管',
    Document: '查看操作说明',
    YearConsumption: '年总消费',
    HintOne: '近12个消费统计',
    HintTwo: '如要查看更多消费记录，可点击账单明细查看',
    ImageSize: '图片小于2M，格式为 JPG、PNG或GIF',
    VideoSize: '视频小于20M，格式为 MP4',
  }
}

//管理员
export const a = {
  HomePage:{
    TotalIncome: '总收益额',
    CreationCodeAmount: '生码总数',
    ScanQRAmount: '扫码总数',
    OrganizationAmount: '组织总数',
    BusinessAmount: '企业总数',
    IndividualAmount: '个体总数',
    IncomeStatistics: '收益统计',
    IncomeStatisticsHint: '当前图表显示的是近12个月的收益统计',
    RevenueCost: '收益费用',
  }
}

//商户
export const b = {
  FunctionList:{
    ProcessManage:'工序管理',
    ProcessList:'工序列表',
    ProcessListDescribe:'产品生产加工工序的添加、编辑和列表',
    ScanQRDistribution:'扫码分配',
    ScanQRDistributionDescribe:'通过单个采集的方式 给下一道工序分配码标',
    ExtentQRDistribution:'首尾码分配',
    ExtentQRDistributionDescribe:'通过首码和尾码的方式 给下一道工序分配一组的码标',
    FileQRDistribution:'文件分配',
    FileQRDistributionDescribe:'通过上传文件的方式 给下一道工序分配码标',
    DistributionList:'分配列表',
    DistributionListDescribe:'查看工序分配的列表和数据',
    DealerManage:'经销商管理',
    DealerList:'经销商列表',
    DealerListDescribe:'经销商的添加、编辑和列表',
    ScanQRShipments:'扫码发货',
    ScanQRShipmentsDescribe:'通过单个采集的方式 给经销商发货',
    ExtentQRShipments:'首尾码发货',
    ExtentQRShipmentsDescribe:'通过首码和尾码的方式 给经销商发去一组货物',
    FileQRShipments:'文件发货',
    FileQRShipmentsDescribe:'通过上传文件的方式 给经销商发货',
    ShipmentsList:'发货列表',
    ShipmentsListDescribe:'查看发货的的列表和数据',
  },
  HomePage:{
    CreationCodeAmount: '生码总数',
    ActiveAmount: '激活总数',
    ScanQRAmount: '扫码总数',
    ProductsAmount: '产品总数',
    ProcessAmount: '工序总数',
    DealerAmount: '经销总数',
    CodeActivationStatistics: '码标激活统计',
    CodeActivationStatisticsDescribe: '当前图表只记录一周的激活数据，如果要查看更多请在统计查询里查看',
    ChinaScanQRStat: '中国省份扫码量占比',
    ChinaScanQRStatDescribe: '所有产品扫码量总和',
    Xpense: '消费',
    Referer: '访问来源',
    ToActivateTheNumber: '激活个数',
    XpenseTracker: '消费记录',
    XpenseTrackerDescribe: '鼠标悬停在点上可查看详情',
    Guangdong: '广东',
    Zhejiang: '浙江',
    Fujian: '福建',
    Shanghai: '上海',
    Hunan: '湖南',
    ExpenseCenter: '费用中心',
    BillingDetails: '账单明细',
    Balance: '账户余额',
    Recharge: '充值',
    Withdraw: '提现',
    BasicEdition: '基础版',
    PayTheTemplate: '付费模块',
    InvoiceManagement: '发票管理',
    InvoicedAmount: '可开发票金额',
    SetInvoiceTitle: '设置发票抬头',
    InvoiceMailingAddress: '设置发票地址',
    AskingForInvoice: '索取发票',
  },
  PromotionSet:{
    PromotionSet: '促销活动功能设置',
    IsWSA: '是否强制关注公众号',
    IsWSAHint: '需要关注公众号才能领红包',
    IsWithdrawalAudit: '是否提现审核',
    IsWithdrawalAuditHint: '提现需要管理员审核允许才能到账',
    IsIntegral: '是否扫码获取积分',
    IsIntegralHint: '开启后扫码将获取积分',
    IsGetUserPhone: '是否获取用户电话号码',
    IsGetUserPhoneHint: '获取手机号码才能领取红包（需要收取短信费用每条0.1元）',
    Withdrawal: '提现金额',
    WithdrawalHint: '提现的最小金额和最大金额设置',
    WithdrawalHintOne: '最小可提现金额',
    WithdrawalHintTwo: '最大可提现金额',
    Remind: '余额不足短信提醒',
    RemindHint: '不设置手机号和余额默认为不短信提醒',
    RemindHintOne: '短信提醒手机号',
    RemindHintTwo: '小于多少余额后提醒',
  },
  ProductManage:{
    AddProduct: '添加产品',
    EditProduct: '编辑产品',
    DataImport: '数据导入',
    SearchHint: '输入产品名称查询',
    TemplateSettings: '模板设置',
    ShelfLife: '保质期',
    Specification: '规格',
    Score: '积分',
    Keyword: '关键词',
    TraceRatio: '溯源率',
    TemplateSelect: '选择模板',
    CancelSelect: '取消选择',
    CommonTemplate: '普通模板',
    AntiFakeTemplate: '防伪模板',
    PromotionTemplate: '促销模板',
    IsShowModule: '是否显示当前模块',
    SeeEffect: '查看扫码效果',
    AddLine: '添加自定义属性',
    LineHint: '可根据产品的需求添加更多自定义的属性内容',
    HintOne: '请输入产品名称',
    HintTwo: '可输入产品保质期',
    HintThree: '可输入产品规格',
    HintFour: '可以输入产品描述',
    HintFive: '自定义名称 例：价格',
    HintSix: '自定义内容 例：99元',
  },
  DeviceManage:{
    AddDevice: '添加设备',
    EditDevice: '编辑设备',
    SearchHint: '输入设备名称查询',
  },
  CodeManage:{
    no: '否',
    CreateCodes: '生成码标',
    CreateBarCodes: '生成条码',
    QRPrinting: '二维码印刷',
    ProductOption: '产品选择',
    CodeLevel: '码标等级',
    CodeNumber: '码标数量',
    BarCodeNumber: '条码数量',
    Figure: '数字（6位数）',
    EnglishFigures: '英文加数字（6位数）',
    Monocode: '单码',
    Casecode: '套码',
    BoxCodeNumber: '箱码数量',
    EachBoxNumber: '每箱个数',
    ProductionType: '生产类型',
    HolderCertificate: '农药登记证持有人',
    ManufacturingConsignment: '委托加工',
    EntrustRepackaging: '委托分装',
    HintOne: '中国二维码认证中心 全球标准',
    HintTwo: '默认方式生成即可用，手动激活需要后台、APP、小程序采集等方式激活',
    HintThree: '是否需要防伪码查询真伪功能',
    HintFour: '是否需要流水码用于区分标签的顺序',
    HintFive: '是否需要默认组箱功能，单码可后期组箱或者不组箱',
    HintSix: '高等级码可以组低等级的码 例:1个2级码组10个1级码 为1拖10',
    HintSeven: '码标生成数由生码套餐决定',
    HintEight: '默认激活需要先选择产品进行绑定',
    HintNine : '查看生码套餐',
    HintEleven: '例：填写10那么就会生成10个箱码',
    HintTwelve: '例：1箱多少个子码 例：填写10则是每箱10个子码，为1拖10',
    HintThirteen: '单次生成最多100万个码标',
  },
  ShhfBrandManage:{
    AddBrand: '添加品牌',
    BrandEdit: '品牌编辑',
    BrandName: '品牌名称',
  },
  ShhfLicensorManage:{
    AddLicensor: '添加授权',
    LicensorEdit: '授权编辑',
    LicensorName: '授权名称',
    FieldsName: '产地位置名称',
  },
  BatchManage:{
    BatchName: '批次名称',
    AddBatch: '添加批次',
    QualityStatus: '质检状态',
    QualityInformation: '质检信息',
    LogisticsStatus: '物流状态',
    LogisticsInformation: '物流信息',
    LogisticsType: '物流类型（请选择物流类型）',
    LogisticsOrderID: '物流单号',
    SFExpress: '顺丰快递',
    STOExpress: '申通快递',
    YTOExpress: '圆通快递',
    YunDaExpress: '韵达快递',
    EMSExpress: '邮政快递',
    HintOne: '输入批次名称查询',
    HintTwo: '请输入自定义批次名称',
    HintThree: '可输入备注信息',
    HintFour: '请输入物流单号',
    HintFive: '可根据质检信息的需求添加自定义文字和图片',
    HintSix: '例：质检时间',
    HintSeven: '例：2017-10-31',
  },
  ChangeActive: {
    ChangeActive: '兑换码激活',
    ExTime: '过期时间',
    ActiveType: '激活方式',
    HINT: '分为采集激活、首尾码激活两种方式',
    CACTIVE: '采集激活',
    PSACTIVE: '首尾码激活',
    HintThree: '选择兑换码对应商品',
  },
  CodeActive:{
    CodeActive: '码标激活',
    ReplaceData: '替换数据',
    ViewDetails: '查看详情',
    HintOne: '分为采集激活、首尾码激活和文件激活三种方式',
    HintTwo: '批次可绑定质检信息，物流信息等内容，如要自定义请在批次管理里先添加',
    HintThree: '选择码标激活的产品',
    HintFour: '在输入框里键入码标后按回车添加，可采集多个一并提交',
    HintFive: '在此键入需要激活的码标',
    HintSix: '在此键入需要激活的码标首码',
    HintSeven: '在此键入需要激活的码标尾码',
  },
  CodePacket:{
    CodeGrouping: '码标分组',
    CombinationType: '组合类型',
    NumberCombination: '组合数',
    NumberSuccessfulCombinations: '成功组合数',
    PacketMode: '分组方式',
    ScanPacket: '采集分组',
    ExtentPacket: '首尾码分组',
    FilePacket: '文件分组',
    OldFilePacket: '单文件分组',
    externalFilePacket: '外部文件分组',
    SeniorCode: '高级码',
    InferiorCode: '低级码',
    InferiorFirstCode: '低级首码',
    InferiorLastCode: '低级尾码',
    SeniorFile: '高级文件包',
    InferiorFile: '低级文件包',
    File: '文件包',
    GroupNumber: '每组个数（1拖几）',
    HintOne: '分为采集分组、首尾码分组和文件分组三种方式',
    HintTwo: '每次确定提交前仅能添加一个，按回车添加至列表',
    HintThree: '在此键入需要分组的高级码',
    HintFour: '在此键入需要跟高级码分组的低级码',
    HintFive: '在此键入需要分组的低级首码',
    HintSix: '在此键入需要分组的低级尾码',
    HintSeven: '请输入每组个数',
  },
  Brand:{
    Brand: '商标',
    AddBatch: '添加商标',
    ChangeBrand: '修改商标',
    BrandName: '商标名称',
    BrandCertificate: '商标证书',
    Authorization: '授权书',
    BindingProduct: '绑定产品',
    Certificate:'证书信息',
    HintOne: '请选择需要绑定的产品',
    HintTwo: '请选择与产品对应的商标进行审核',
    HintThree: '请输入商标名称',
    HintFour: '商标证书和授权书有一样必须上传',
  },
  Process:{
    AddProcess: '添加工序',
    DistributorDelivery: '经销商发货',
    BatchManage: '批次管理',
    ReceivingProcess: '收货工序',
    ProcessAllot: '工序分配',
    AllotType: '分配方式',
    AllotCodes: '分配码标',
    ScanAllot: '采集分配',
    ExtentAllot: '首尾码分配',
    FileAllot: '文件分配',
    HintOne: '输入工序名称查询',
    HintTwo: '分为采集分配、首尾码分配和文件分配三种方式',
    HintThree: '选择码标分配的产品',
    HintFour: '选择码标分配的工序',
    HintFive: '在此键入需要分配的码标',
    HintSix: '在此键入需要分配的码标首码',
    HintSeven: '在此键入需要分配的码标尾码',
  },
  Dealer:{
    AddDealer: '添加经销商',
    CodesShipping: '码标发货',
    ShippingCodes: '码标发货',
    CreationDate: '生产时间',
    Nodate: '无',
    CurrentDate: '系统当前时间',
    CustomDate: '自定义时间',
    ShippingType: '发货方式',
    ScanShipping: '采集发货',
    ExtentShipping: '首尾码发货',
    FileShipping: '文件发货',
    ReceivingDealer: '收货经销商',
    ShippingDealer: '发货经销商',
    ShippingOutlets: '发货网点',
    HintOne: '输入经销商名称查询',
    HintTwo: '分为采集发货、首尾码发货和文件发货三种方式',
    HintThree: '选择码标发货的产品',
    HintFour: '选择收货的经销商',
    HintFive: '在此键入需要发货的码标',
    HintSix: '在此键入需要发货的码标首码',
    HintSeven: '在此键入需要发货的码标尾码',
    HintEight: '请选择时间',
    HintNine: '生产日期将显示在扫码展示页面',
  },
  Material:{
    UploadImages: '上传图片',
    UploadAudio: '上传音频',
    UploadVideo: '上传视频',
    GroupingManage: '分组管理',
    StorageSpace: '存储空间',
    UsedStorage: '已使用',
    SurplusStorage: '剩余空间',
    Loading: '正在加载空间存储数据...',
    ImageSizeHint: '上传的图片大小不超过2M，压缩图片大小方式',
    AudioSizeHint: '上传的音频大小不超过5M，压缩音频大小方式',
    VideoSizeHint: '上传的视频大小不超过10M，压缩视频大小方式',
  },
  BasicInfo:{
    Regdate: '注册时间',
    OIDNumber: 'OID编号',
    MerchantNumber: '商户编号',
    UploadLogo: '上传LOGO',
    BusinessInformation: '商户信息',
    FunctionList: '功能列表',
    Developer: '开发者中心',
    BusinessType: '商户类型',
    BusinessName: '商户名称',
    BusinessLicense: '营业执照',
    UploadLicense: '上传执照',
    Industrial: '所属行业',
    CreditCode: '信用代码',
    BusinessIntroduction: '商户简介',
  },
  FuncitonPackage:{
    HintOne: '注意：该订单二维码已失效',
    WeChatScanPay: '微信扫码支付',
  },
  Statistics:{
    FunctionPackage: '生码套餐',
    FunctionModule: '功能模块',
    Promotion: '促销红包',
    ConsumptionType: '消费类型',
    Monetary: '消费金额',
  },
  SecuritySettings:{
    SecurityLevel: '安全级别',
    SecuritySet: '安全设置',
    CompletionSchedule: '完成进度',
    LoginPassword: '登录密码',
    BindEmail: '绑定邮箱',
    BindingPhone: '绑定手机',
    BindingWeChat: '绑定微信',
    SafetyProblem: '安全问题',
    Unsubscribe: '注销用户',
    HintOne: '安全性高的密码可以使帐号更安全。建议您定期更换密码，设置一个包含字母，符号或数字中至少两项且长度超过6位的密码。',
    HintTwo: '您已绑定了邮箱',
    HintThree: '[链接OID发送的各类系统、活动、服务通知将发送到您的邮箱]',
    HintFour: '您已绑定了手机',
    HintFive: '您的手机为安全手机，可以找回密码，但不能用于登录',
    HintSix: '用于扫码登录、余额提现和消息推送',
    HintSeven: '建议您设置三个容易记住，且最不容易被他人获取的问题及答案，更有效保障您的密码安全。',
    HintEight: '如果您不再使用此账号，可以将其注销。账号成功注销后，其下所有服务、数据及隐私信息将会被删除并将无法恢复。',
  }
}
